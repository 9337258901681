import { isMobile } from './utils'

class Toast {
  toasts = []
  options

  constructor() {
    this.options = {
      color: 'primary',
      stacking: true,
      hidden: false,
      autohide: true,
      width: '450px',
      position: 'top-right',
      delay: 1000
    }
  }

  // changeOptions(custom) {
  //   this.options = custom
  // }

  /** Adds a new alert to the DOM and toasts
   * @returns index of this newly created alert in toasts array
   */
  makeNew(textColor = 'white') {
    this.flush()

    let a = document.createElement('div')
    a.innerHTML = `<div class="toast-body">-</div>`

    const uuid = Math.random().toString(36).substring(2, 6)
    const toastClass = `toast-${uuid}`
    a.classList.add(
      'toast',
      'fade',
      'position-fixed',
      'top-20',
      'left-0',
      'right-0',
      'mx-auto',
      toastClass
    )
    a.style.cssText = `z-index:9999;max-width:max-content;--mdb-toast-max-width:inherit`
    if (textColor) a.classList.add(textColor)

    if (document.querySelector(`.${toastClass}`)) {
      return
    }

    document.body.appendChild(a)
    let index = this.toasts.push(a)
    return index - 1
  }

  // yellow/orange alert
  toast(type = 'warning', text, title = false, customOptions) {
    // if there is called on mobile, just show alert
    if (isMobile()) {
      alert(text)
      if (customOptions?.callback) customOptions.callback()
      return
    }

    const colors = {
      warning: 'toast-warning',
      info: 'toast-info',
      success: 'toast-success',
      danger: 'toast-danger'
    }
    let a = this.makeNew(colors[type])

    let options = {
      color: 'warning',
      stacking: false,
      hidden: false,
      autohide: true,
      width: '450px',
      position: 'center',
      delay: 2000,
      ...customOptions
    }

    // document.querySelector('.toast-header').hidden = true
    document.querySelector('.toast-body').innerHTML = text
    let alertInstance = new mdb.Toast(this.toasts[a], options)

    if (alertInstance._element && alertInstance._element.innerText !== '-') {
      alertInstance.show()
    }

    // remove alert from dom after hidden
    if (this.toasts[a]) {
      this.toasts[a].addEventListener('hidden.mdb.toast', function () {
        this.remove()
        customOptions?.callback()
      })
    }
  }

  /** Wipe all toasts from DOM */
  flush() {
    this.toasts.forEach(a => a.remove())
  }
}

export default Toast

const PLANS = {
  1: {
    name: 'A 個人',
    pretaxAmount: '¥120,000',
    tax: '¥12,000',
    totalAmount: '¥132,000',
    desc: 'Plan A 個人（年間）'
  },
  2: {
    name: 'B ベーシック',
    pretaxAmount: '¥600,000',
    tax: '¥60,000',
    totalAmount: '¥660,000',
    desc: 'Plan B ベーシック'
  },
  3: {
    name: 'C ビジネス',
    pretaxAmount: '¥3,600,000',
    tax: '¥360,000',
    totalAmount: '¥3,960,000',
    desc: 'Plan C ビジネス'
  },
  4: {
    name: 'D エンタープライズ',
    pretaxAmount: '¥5,000,000',
    tax: '¥500,000',
    totalAmount: '¥5,500,000',
    desc: 'Plan D エンタープライズ'
  },
  5: {
    name: 'A 個人',
    pretaxAmount: '¥12,000',
    tax: '¥1,200',
    totalAmount: '¥13,200',
    desc: 'Plan A 個人（月間）'
  }
}

export { PLANS }
